let language = window.localStorage.getItem('language')

const params = window.location.search.substr(1)
  .split('&')
  .map(item => item.split('='))

const langIndex = params.findIndex(pair => pair[0] === 'lang')

let urlLang
if (langIndex !== -1) {
  urlLang = params.splice(langIndex, 1)[0]
  params.map(pair => pair.join('=')).join('&')
  history.replaceState(null, '', window.location.href.replace((params.length === 0 ? '?' : '') + urlLang.join('='), ''))
}

if (urlLang || !language) {
  const _language = (urlLang && urlLang[1]) || 'en_US'

  language = _language
  window.localStorage.setItem('language', _language)
}
