/**
* 本组件为 IAM 登录的对接组件，只需在入口文件中引入并调用即可
*
* Example:
* import IAM from './utils/IAM'
*
* IAM(
*   'example-token',
*   'http://example.com',
*   data => data.isLogin ? '' : data.loginUrl,
*   data => data.token,
* )
*
* ----------------------------------------------------
* !!!注意!!!
*
* 登录完成后，token 将会存储在 localStorage 中
* 后续的请求需要将 token 带在 Authorization 请求头中
* 由于 token 获取是异步的缘故，可能会导致请求在获得 token 之前，进而导致循环登录
* 因此，提供了"login"这个 Promise，来判断是否应延迟请求、渲染等
*/

let _resolve: (value: unknown) => void

// 是否登录的 Promise，登录完成后变为 resolve 状态
function resetLogin() { return window.login = new Promise(resolve => { _resolve = resolve }) }

/**
* IAM 登录对接函数
*
* @param {string}    tokenName       应用的token名称
* @param {string}    domain          后端接口域名
* @param {Function}  validateHandle  any => string，validate接口处理函数，输入值为接口调用的返回值，返回值为''代表已登录，否则应为将要跳转的url
* @param {Function}  viewHandle      any => string，token获取接口处理函数，输入值为接口调用的返回值，返回值为token字符
* @param {string}    validateUri     登录校验接口uri
* @param {string}    viewUri         token获取接口uri
* @param {string}    tokenHeader     携带token的请求头
* @param {Function}  tokenHandle     token处理函数
*/
window.IAM = function IAM(
 tokenName: string,
 domain: string,
 validateHandle: (data: any) => string,
 viewHandle: (data: any) => string,
 validateUri: string = '/account/user/validate',
 viewUri: string = '/account/user/view',
 tokenHeader: string = 'Authorization',
 tokenHandle: (token: string) => string = token => `Bearer ${token}`
) {
  resetLogin().then(() => {
    const show = getRun => polling(() => {
      const run = getRun()

      run && run()
      return !run
    }, 100)

    show(() => window.showMenu)
    show(() => window.showNavbar)
  })

  const params = window.location.search

  if (
    params.includes('state=') &&
    params.includes('session_state=') &&
    params.includes('code=')
  ) {
    history.replaceState(null, '', window.location.href.replace(/\?.*#/, '#'))

    fetch(domain + viewUri + params)
      .then(response => response.json())
      .then(data => {
        if(+data.code === 604 && window?.userInfo?.flag === 2) {
          window.location.href = '/#/dashboard/unable-open'
          return Promise.reject()
        }

        const token = viewHandle(data)

        if (token) {
          window.localStorage.setItem(tokenName, token)
          const cbPath = sessionStorage.getItem('cb-path')
          cbPath && sessionStorage.removeItem('cb-path')
          window.location.href = cbPath || '/#/dashboard'
        }
      })
      .then(_resolve)
  } else {
    fetch(domain + validateUri, { headers: new Headers({ [tokenHeader]: tokenHandle(window.localStorage.getItem(tokenName)!) }) })
      .then(response => response.json())
      .then(data => {
        const url = validateHandle(data)

        if (url === '') _resolve(null)
        else {
          window.sessionStorage.setItem('cb-path', location.href.replace(/\?.*/, ''))
          process.env.NODE_ENV === 'production' && (window.location.href = url)
        }
      })
  }
}

/**
 * 轮询函数
 * @param {Function}  run       轮询执行的内容，返回值为布尔值，用于判断是否继续执行下一轮轮询
 * @param {number}    interval  轮询时间间隔，单位毫秒
 */
function polling(run: () => boolean, interval: number) {
  run() && setTimeout(() => polling(run, interval), interval)
}