const config = [
  { path: "/dashboard", name: "@dashboard" },
  { path: "/uc", name: "@uc" },
  { path: "/security", name: "@security" },
  { path: "/cost", name: "@cost" },
  { path: "/doc", name: "@doc" },
  { path: "/workorder", name: "@workorder" },
  { path: "/ecs", name: "@ecs" },
  { path: "/member", name: "@member" },
  { path: "/msp", name: "@msp" },
];

export default [
  {
    type: "div",
    attrs: [{ name: "style", value: "display: flex; flex-direction: column;" }],
    routes: [
      { type: "nav", routes: [{ type: "application", name: "@navbar" }] },
      {
        type: "div",
        attrs: [{ name: "style", value: "flex: 1; display: flex;" }],
        routes: [
          {
            type: "menu",
            attrs: [{ name: "style", value: "margin: 0; padding: 0;" }],
            routes: [{ type: "application", name: "@menu" }],
          },
          {
            type: "div",
            attrs: [
              { name: "style", value: "flex: 1; overflow: auto;" },
              {
                name: "id",
                value: "container",
              },
            ],
            routes: config.map(({ path, name }) => ({
              path,
              type: "route",
              routes: [{ type: "application", name }],
              default: false,
            })),
          },
        ],
      },
    ],
  },
];
