const isDev = process.env.NODE_ENV === 'development'
const staticHost = isDev
  ? 'http://localhost:51804'
  : 'http://home.console.edgenext.com/statics'

export default {
  apps: {
    '@navbar': '//home.console.edgenext.com/navbar/entry.json',
    '@menu': '//home.console.edgenext.com/menu/entry.json',
    '@dashboard': '//dashboard.console.edgenext.com/entry.json',
    '@uc': '//usercenter.console.edgenext.com/entry.json',
    '@cdn': '//cdn.console.edgenext.com/entry.json',
    '@security': '//yundun.console.edgenext.com/entry.json',
    '@gateway': '//gateway.console.edgenext.com/entry.json',
    '@sdwan': '//sdwan.console.edgenext.com/entry.json',
    '@orchsym': '//orchsym.console.edgenext.com/entry.json',
  },
  cost: {
    publicUrl: `//usercenter.console.edgenext.com/`,
    apiHost: '//usercenter-api.console.edgenext.com',
  },
  navbar: {
    publicUrl: `//home.console.edgenext.com/navbar/`,
    apiHost: '//account.console.edgenext.com',
  },
  menu: {
    publicUrl: `//home.console.edgenext.com/menu/`,
    apiHost: '//account.console.edgenext.com',
  },
  userCenter: {
    publicUrl: `//usercenter.console.edgenext.com/`,
    apiHost: '//usercenter-api.console.edgenext.com',
  },
  dashboard: {
    publicUrl: `//usercenter.console.edgenext.com/`,
    apiHost: '//dashboard-api.console.edgenext.com',
  },
  logo: `${staticHost}/Logo.svg`,
  QRCode: `${staticHost}/QR_Code.jpg`,
  projectName: {
    zh_CN: 'EdgeNext',
    en_US: 'EdgeNext',
  },
  website: 'https://www.edgenext.com/',
}
