const appStyleSheets: {[app: string]: CSSStyleSheet[]} = {}

window.dynamicLink = {
  mount: (
    cssTag: string,
    domain: string,
    env: 'production' | 'development',
    filename: string = 'main.css'
  ) => () => {
    // 这里的地址需要修改为子应用的部署路径
    // 如：部署地址为 example.com/testProj
    // 则 fetch 请求地址应为 http://example.com/testProj/asset-manifest.json
    if (cssTag && env === 'production') {
      const styleSheets = appStyleSheets[cssTag]

      return new Promise<void>(resolve => {
        if (styleSheets && styleSheets.length) {
          styleSheets.forEach(styleSheet => styleSheet.disabled = false)
          resolve()
        } else fetch(domain + '/asset-manifest.json', { cache: 'no-cache' })
          .then(res => res.json())
          .then(data => Promise.resolve(data.files))
          .then(manifest => {
            const link = document.createElement('link')

            // 这里的文件名应改为 asset-manifest.json 中 css 入口文件的键名，一般同 entry 配置的键名，默认为 main
            link.href = manifest[filename]
            link.onload = <any>resolve
            link.setAttribute('data-tag', cssTag)
            link.setAttribute('rel','stylesheet')
            link.setAttribute('type','text/css')

            document.head.appendChild(link)
          })
      })
    }

    return Promise.resolve()
  },

  unmount: (cssTag: string) => () => {
    if (cssTag) {
      if (!appStyleSheets[cssTag]) {
        const links = document.querySelectorAll(`link[data-tag=${cssTag}]`) as unknown as HTMLLinkElement[]
        const styleSheets: CSSStyleSheet[] = [].map.call(links, link => [].find.call(document.styleSheets, item => item.href === link.href))

        appStyleSheets[cssTag] = styleSheets
      }

      appStyleSheets[cssTag].forEach(styleSheet => styleSheet.disabled = true)
    }

    return Promise.resolve()
  }
}