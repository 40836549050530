import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from 'single-spa-layout'
import { registerMicroApps, start as qiankunStart } from 'qiankun'

import routesConfig from './routesConfig'
import apps from '../apps.json'
import './services'
import constants from './constants'
import qiankunMicroApps from './qiankunMicroApps'

import * as Sentry from '@sentry/browser';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: 'http://key@domain/15',
  tunnel: "https://metrics.console.edgenext.com/front_data/report",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 1.0,
});
const path = window.location.href.replace(window.location.origin, '')

if (path === '' || path === '/' || path === '/#' || path === '/#/')
  window.location.href = '/#/dashboard'

const entrypoints: { [app: string]: string } = {}

const routes = constructRoutes(
  // document.querySelector("#single-spa-layout") as HTMLTemplateElement,
  { mode: 'hash', routes: routesConfig }
)

const applications = constructApplications({
  routes,
  async loadApp({ name }) {
    // 线下用户不能进入费用中心
    if (name === '@cost' && window?.userInfo?.flag === 2) {
      window.location.href = '/#/dashboard'
    }
    window.showLayoutLoading()
    await loginApp(name)
    if (process.env.NODE_ENV !== 'production') return System.import(name)

    return entrypoints[name] !== undefined
      ? System.import(entrypoints[name])
      : fetch(apps[name], { cache: 'no-cache' })
          .then(res => res.json())
          .then(data => {
            entrypoints[name] = data.entrypoint
            return System.import(entrypoints[name])
          })
  }
})

const layoutEngine = constructLayoutEngine({ routes, applications })

// 注册子应用前，先登录主应用->获取用户信息
fetchUserInfo().then(() => {
  applications.forEach(registerApplication)
  registerMicroApps(qiankunMicroApps, {
    beforeLoad({ name }) {
      window.showLayoutLoading()
      return loginApp(name)
    }
  })
})

layoutEngine.activate()
start()
qiankunStart({
  prefetch: process.env.NODE_ENV === 'procduction',
  sandbox: false,
  fetch() {
    return fetch(arguments[0], {
      cache: 'no-cache',
      ...arguments[1]
    })
  }
})

function fetchUserInfo() {
  // 时区设置，系统时区变化时，更新到本地中，供其它子应用读取。仅在使用本地时区时操作，后续可切换时区操作时，此处不可用，需要到 fe-navbar 中进行处理
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  if (window.localStorage.getItem('Time-Zone') !== timeZone) window.localStorage.setItem('Time-Zone', timeZone)

  return new Promise<void>(resolve => {
    if (!!window.userInfo) resolve()

    fetch(constants.ACCOUNT_URL, {
      credentials: 'include',
      headers: {
        'Accept-Language': window.localStorage.getItem('language') || 'en_US',
        'Time-Zone': window.localStorage.getItem('Time-Zone') || timeZone
      }
    })
      .then(res => res.json())
      .then(res => {
        window.userInfo = res.data
        localStorage.setItem('token', res?.data?.token || '')
        //上报用户信息，之后每次上报都会带用户信息
        Sentry.setUser({ username: res.data.username, email: res.data.email });
      })
      .finally(() => {
        resolve()
      })
  })
}

// 登录子应用，获取产品开通信息
async function loginApp(appName: string) {
  if (!constants.appPathMapProduct[appName]) return Promise.resolve()

  const productPath = location.hash.split('/')?.[2] || ''
  const product = constants.appPathMapProduct[appName][productPath]

  if (!product) return Promise.resolve()

  // 获取产品开通信息
  const token = localStorage.getItem('token') || undefined
  const { code = 0, data = {} } = await fetch(
    `${constants.DASHBOARD_API_URL}/account/user/appLogin?app=${product}`,
    {
      credentials: 'include',
      headers: {
        Authorization: `BearerX ${token}`
      }
    }
  ).then(res => res.json())

  // 未登录
  if (code === 44) {
    // ucIAM()
    window.sessionStorage.setItem('cb-path', location.href.replace(/\?.*/, ''))
    process.env.NODE_ENV === 'production' && (window.location.href = data.url)
    return Promise.reject()
  }

  // 未开通
  if (code === 604) {
    // 线下用户
    if (window?.userInfo?.flag === 2) {
      window.location.href = '/#/dashboard/unable-open'
    } else {
      window.location.href = `/?app=${product}#/dashboard`
    }

    return Promise.reject()
  }

  return Promise.resolve()
}

function ucIAM() {
  // 登录白名单，不需要登录的应用
  const loginWhiteListPath = [
    'dashboard/logout',
    'dashboard/impersonate',
    'dashboard/unable-open',
    '/doc',
    'uc/register',
    'uc/resetPwd',
    'uc/verification',
    'member/role',
    'member/accept'
  ]
  if (loginWhiteListPath.some(item => location.href.includes(item))) return Promise.resolve()

  const params = window.location.search
  const isUc = window.location.hash.split('/')[1] === 'uc'

  const tokenName = 'uc-token'
  const domain = constants.USER_CENTER_API_URL
  const validateUri = '/account/user/validate'
  const viewUri = '/account/user/view'

  if (
    params.includes('state=') &&
    params.includes('session_state=') &&
    params.includes('code=') &&
    isUc
  ) {
    history.replaceState(null, '', window.location.href.replace(/\?.*#/, '#'))

    return fetch(domain + viewUri + params)
      .then(response => response.json())
      .then(({ data }) => {
        const token = data.token

        if (token) {
          window.localStorage.setItem(tokenName, token)
          const cbPath = sessionStorage.getItem('cb-path')
          cbPath && sessionStorage.removeItem('cb-path')
          window.location.href = cbPath || '/#/dashboard'
          return Promise.resolve()
        }

        return Promise.reject()
      })
  } else {
    const headers = { Authorization: `Bearer ${localStorage.getItem(tokenName)}` }

    return fetch(domain + validateUri, { headers })
      .then(response => response.json())
      .then(({ data, code }) => {
        const url = code !== 44 ? '' : data.url

        if (url === '') return Promise.resolve()
        else {
          window.sessionStorage.setItem('cb-path', location.href.replace(/\?.*/, ''))
          process.env.NODE_ENV === 'production' && (window.location.href = url)
          return Promise.reject()
        }
      })
  }
}

window.addEventListener("single-spa:before-app-change", (evt) => {
  const detail = (evt as CustomEvent).detail;
  new Promise((resolve) => {
    let unmountedAppName = detail.appsByNewStatus.NOT_MOUNTED.filter((item) => item !== '@menu' && item !== '@navbar')[0];
    if (detail?.appsByNewStatus?.NOT_MOUNTED.length > 0 && unmountedAppName) {
      Sentry.withScope(scope => {
        scope.setExtra('session:duration', Date.now() - Number(scope.getScopeData().extra['session:start']));
        scope.captureMessage('session')
        resolve(null)
      });
    } else {
      resolve(null)
    }
  }).then(() => {
    let appName = detail.appsByNewStatus.MOUNTED.filter((item) => item !== '@menu' && item !== '@navbar')[0];
    if (detail?.appsByNewStatus?.MOUNTED.length > 0 && appName) {
      Sentry.setTags({
        dist: 'edgenext-' + appName.replace('@', ''),
      })
      Sentry.setExtra('session:start', Date.now());
    }
  })

});