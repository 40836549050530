const userApi = '/auth/realms/edgenext/protocol/openid-connect/user'

const ACCOUNT_URL = process.env.NODE_ENV === 'development'
  ? '//account.console.prxcdn.com' + userApi
  : window.location.origin.replace('home', 'account') + userApi

const USER_CENTER_API_URL = process.env.NODE_ENV === 'development'
  ? '//usercenter-api.console.prxcdn.com'
  : window.location.origin.replace('home', 'usercenter-api')


const DASHBOARD_API_URL = process.env.NODE_ENV === 'development'
  ? '/dashboard-api'
  : window.location.origin.replace('home', 'dashboard-api')

// 安全子应用名称映射产品开通时传入的名字
const appPathMapProduct = {
  '@security': {
    'anti-ddos': 'ddos_protection'
  },
  console: {
    'cloud-speed': 'scdn',
    'cloud-resolving': 'dns'
  }
}

export default {
  ACCOUNT_URL,
  USER_CENTER_API_URL,
  appPathMapProduct,
  DASHBOARD_API_URL
}