const apps = {
  console: '/console/',
  live: '/live/',
  bms: '/bms/'
}

export default Object.entries(apps).map(([name, entry]) => ({
  name,
  entry,
  container: '#container',
  activeRule: `#/${name}`,
}))
